import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Link from '../utils/link'
import Pdf from '../assets/images/pdf.svg'
import Upload from '../assets/images/upload.svg'
import Seo from '../components/seo'
import Dropzone from 'react-dropzone'
import Map from '../components/map'

import Instagram from '../assets/images/instagram-blue.svg'
import Facebook from '../assets/images/facebook-blue.svg'
import Twitter from '../assets/images/twitter-blue.svg'
import Pinterest from '../assets/images/pinterest-blue.svg'

/*
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
*/
const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k)=>{
    formData.append(k,data[k])
  });
  return formData
}

class Form extends Component {
  state = {
    files: [],
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onDrop = (files) => {
    this.setState({
      files: files,
      'file_upload': files[0]
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      //headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //headers: { "Content-Type": "multipart/form-data" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {
    let props = {
      ref: "form",
      name: "retail_registration",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    let { files } = this.state

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for submitting an application.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className='form__row'>
          <Dropzone onDrop={this.onDrop} multiple={false}>
            {({getRootProps, getInputProps}) => (
              <div  {...getRootProps({className: 'dropzone form__dropzone'})}>
                <div className='form__file'>
                  <input name='file_upload' {...getInputProps()} />
                  <img src={Upload} alt='Upload File' />
                  <p>Drag and drop<br />
                  Or browse to choose a file</p>
                  { files.length === 0 && <span>.png, .jpg, .pdf accepted, up to 6MB</span> }
                  { files.length > 0 && files.map((file, i) =>
                    <div key={i}>{file.name} - {file.size} bytes</div>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div className="form__row form__row--double">
          <div className='form__col'>
            <input
              type="text"
              name="first_name"
              placeholder="First Name*"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className='form__col'>
            <input
              type="text"
              name="last_name"
              placeholder="Last Name*"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Your Email*"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form__row form__row--submit">
          <button type="submit" className="btn">
            Submit
          </button>
        </div>
      </form>
    )
  }
}

class RetailRegistrationPage extends Component {

  render() {

    let { creditApplication } = this.props.data.wp.siteSettings.settings

    return (
      <>
        <Seo title='Retail Registration - Lenoxx' description='' />
        <section className='basic'>
          <div className='basic__inner'>
            <div className='basic__column'>
              <h1>Retail Registration</h1>
              <div className='basic__download-wrapper'>
                <p>To register as a retailer, please fill out our credit application form.</p>
                <a href={creditApplication?.localFile?.publicURL} download>
                  <img src={Pdf} alt='Application Form' />
                  <span>Credit Application Form</span>
                </a>
              </div>
              <p>Once completed, upload it here with your name and email address below.</p>
              <Form />
            </div>
          </div>
          <div className='basic__map'>
            <Map />
            <div className='basic__contact'>
              <div className='basic__contact-item'>
                <h4>Lenoxx HQ</h4>
                <p>6 Oban Court<br />
                Laverton North <br />
                Vic 3026</p>
              </div>
              <div className='basic__contact-item'>
                <h4>Office Hours</h4>
                <p>Monday — Friday<br />
                9:00am — 5:00pm</p>
              </div>
              <div className='basic__contact-item'>
                <h4>Contact</h4>
                <p><Link to='tel:+61392572100'>03 9257 2100</Link><br />
                <Link to='mailto:lenoxx@lenoxx.com.au'>lenoxx@lenoxx.com.au</Link></p>
                <ul className="footer__social">
                  <li>
                    <a href="https://www.instagram.com/lenoxxelectronics/" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Instagram} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/LenoxxElectronics" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Facebook} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/LenoxxAustralia" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Twitter} alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com.au/lenoxx/" target='_blank' rel='nofollow noopener noreferrer'>
                      <img src={Pinterest} alt="Pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const query = graphql`
  query {
    wp {
      siteSettings {
        settings {
          creditApplication {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default RetailRegistrationPage
